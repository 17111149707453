<template>
  <div>
    <order-detail
      v-if="shipmentOrder"
      :key="reloadkey"
      :shipment-order="shipmentOrder"
      @load-shipment="loadShipmentDetails"
    />
    <template v-else>
      <b-card class="text-center p-4">
        <b-spinner />
      </b-card>
    </template>
  </div>
</template>

<script>
import { apiToastWarning } from '@/@core/utils/toast'
import { BCard, BSpinner } from 'bootstrap-vue'
import store from '@/store'
import { GET_SHIPMENT } from '@/store/modules/shipment.module'
import OrderDetail from '../order/OrderDetail.vue'

export default {
  name: 'Shipment',
  components: {
    OrderDetail,
    BCard,
    BSpinner,
  },
  data() {
    return {
      shipmentOrder: null,
      reloadkey: 1,
    }
  },
  created() {
    this.loadShipmentDetails()
  },
  methods: {
    loadShipmentDetails() {
      const shipmentId = this.$route.params.shipmentId
      store.dispatch(GET_SHIPMENT, shipmentId)
        .then(res => {
          const shipment = res.data.data
          this.shipmentOrder = {
            ...shipment,
            shipmentId: shipment._id,
          }
          this.reloadkey++
        })
        .catch(err => {
          apiToastWarning(err)
        })
    },
  },
}
</script>
